import { FormattedHTMLMessage } from 'react-intl';

export const Info = () => (
  <div className="dialog__content__section">
    <div className="form-group">
      <label>
        <small>
          <FormattedHTMLMessage id="information" />
        </small>
      </label>
    </div>
  </div>
);
