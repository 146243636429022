import * as React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Error, Footer, ImageContainer, Info, Reference, ReferenceInfo, Title } from './components';
import * as apx from './general/apxService';
import { errors, getMessages, isReference, languages, parseUrl, redirectToUrl, url } from './general/generalUtil';
import { ErrorData, Prescription } from './general/models';

interface State {
  loading: boolean;
  error: ErrorData | undefined;
  prescription: Prescription | undefined;
  language: string;
  reference: string | undefined;
  productId: string | undefined;
  redirect: string | undefined;
}

class Checkout extends React.Component<any, State> {
  constructor(props:any) {
    super(props);
    this.state = {
      loading: true,
      error: undefined,
      prescription: undefined,
      language: languages[navigator.language.split(/[-_]/)[0]] ? navigator.language.split(/[-_]/)[0] : 'en',
      reference: undefined,
      productId: undefined,
      redirect: undefined,
    };
  }

  async componentDidMount() {
    try {
      const user = await apx.get(apx.service.users, 'me');

      if (user.id != null) {
        await this.checkParameters();
      } else {
        this.setState({ loading: false, error: errors.unexpectedError });
      }
    } catch (e) {
      let error:any = await e;

      if (!(error instanceof Object)) {
        error = JSON.parse(error);
      }

      if (error.code === 104 || error.code === 108) {
        redirectToUrl(`${url.pages}/login/?redirect=${location}`);
        return;
      }
      this.setState({ loading: false, error: errors.unexpectedError });
    }
  }

  checkParameters = async () => {
    try {
      const query = parseUrl(location.search);

      if (query.reference != null) {
        const reference = query.reference.toString().toUpperCase();

        if (isReference(reference)) {
          await this.getReference(reference);
        } else {
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
      }

      if (query.redirect) {
        this.setState({ redirect: query.redirect.toString() });
      }
    } catch (e) {
      this.setState({ loading: false, error: errors.unexpectedError });
    }
  };

  getReference = async (reference: string) => {
    try {
      const prescription: Prescription = await apx.get(apx.service.prescription, reference);
      if (prescription.id) {
        await this.getProduct(prescription.package_id);
        this.setState({ reference, prescription });
      } else {
        this.setState({ loading: false, reference, prescription: undefined, productId: undefined });
      }
    } catch (e) {
      const error:any = await e;
      if (error.code === 16) {
        this.setState({ loading: false, reference, prescription: undefined, productId: undefined });
      } else {
        this.setState({ loading: false, error: errors.unexpectedError });
      }
    }
  };

  getProduct = async (packageId: string) => {
    try {
      const data = await apx.get(apx.service.payments, `products?tags=package_${packageId}&tags=prescription_checkout`);
      if (data.data.length === 1) {
        this.setState({ loading: false, productId: data.data[0].id });
      } else {
        this.setState({ loading: false, prescription: undefined, productId: undefined });
      }
    } catch (e) {
      this.setState({ loading: false, error: errors.unexpectedError });
    }
  };

  onChangeLanguage = (language: string) => {
    this.setState({ language });
  };

  render() {
    return (
      <div className="container">
        <div className="dialog">
          <IntlProvider locale={this.state.language} messages={getMessages(this.state.language)}>
            <div className="dialog__content">
              <div className="dialog__content__section">
                <Title>
                  <FormattedMessage id="prescription_checkout_title" />
                </Title>
                { this.state.loading && <FormattedMessage id="loading" /> }
                { !this.state.loading && this.state.error && <Error error={this.state.error} /> }
                {
                  !this.state.loading && !this.state.error && (
                    <>
                      <ReferenceInfo />
                      <Reference
                        prescription={this.state.prescription}
                        reference={this.state.reference}
                        checkReference={this.getReference}
                        productId={this.state.productId}
                        redirect={this.state.redirect}
                      />
                    </>
                  )
                }
              </div>
              <Info />
            </div>
          </IntlProvider>
          <ImageContainer />
        </div>
        <Footer currentlanguage={this.state.language} onChange={this.onChangeLanguage} />
      </div>
    );
  }
}

export default Checkout;
