interface Props {
  handleGoBack?: () => any;
  children: any;
}

export const Title = (props: Props) => (
  <div className="dialog__content__title">
    {
      props.handleGoBack && (
        <a className="material-icons" href="#" onClick={props.handleGoBack}>
        arrow_back
        </a>
      )
    }
    <h1>{ props.children }</h1>
  </div>
);
