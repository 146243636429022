import { languages } from '../general/generalUtil';

interface Props {
  currentlanguage: string;
  onChange: (language: string) => any;
}

export const Footer = (props: Props) => (
  <footer>
    {
      languages &&
      Object.keys(languages).map((language: string) => (
        <a href="#" key={language} className={props.currentlanguage === language ? 'active' : ''} onClick={() => props.onChange(language)}>
          { languages[language] }
        </a>
      ))
    }
  </footer>
);
