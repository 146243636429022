const baseUrl = process.env.REACT_APP_TARGET_HOST;

export const service = {
  users: `${baseUrl}/users/v1/`,
  prescription: `${baseUrl}/prescriptions/v1/`,
  payments: `${baseUrl}/payments/v1/`,
};

const limitMax = 20;

export function get(url: string, action = '', data?: any[]) {
  return request('GET', url, action, data);
}
export function post(url: string, action = '', data?: any[]) {
  return request('POST', url, action, data);
}
export function put(url: string, action = '', data?: any[]) {
  return request('PUT', url, action, data);
}
export function del(url: string, action = '', data?: any[]) {
  return request('DELETE', url, action, data);
}
export async function getAll(url: string, action = '', data?: any[]) {
  let offset = 0;
  let results: any = [];
  let total = 0;

  do {
    const result = await get(url, `${action}&limit(${limitMax},${offset})`, data);
    results = [...results, ...result.data];
    total = result.page.total;
    offset = result.page.limit + offset;
  } while (total > offset);

  return { data: results };
}

async function request(type: string, url: string, action: string, data?: any[]) {
  // console.log('APX_REQUEST(' + type + '):' + url + action);
  const call: RequestInit = {
    method: type,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  let apiError = true;
  let output;

  const response = await fetch(url + action, call);
  if (response.ok) {
    apiError = false;
  }

  const contentType = response.headers.get('content-type');

  if (contentType !== null && contentType.startsWith('application/json')) {
    output = response.json();
  } else if (contentType === 'application/pdf') {
    output = response.blob();
  } else {
    output = response.text();
  }

  if (apiError) {
    throw output;
  }

  return output;
}
