import React, { useState, useEffect } from 'react';
import packageJson from '../package.json';

const refreshCacheAndReload = async () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    const names = await caches.keys();
    for (const name of names) {
      await caches.delete(name);
    }
  }
  // delete browser cache and reload
  window.location.reload();
};

function withClearCache(ChildComp: React.ComponentType<any | string>) {
  function ClearCacheComponent(props: any) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      async function checkMeta() {
        const resp = await fetch(`${process.env.PUBLIC_URL}/meta.json`);
        const meta = await resp.json();

        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        if (latestVersionDate > currentVersionDate) {
          setIsLatestBuildDate(false);
          await refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      checkMeta();
    }, []);

    return (
      <>
        { isLatestBuildDate ? <ChildComp {...props} /> : null }
      </>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
