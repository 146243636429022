import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { icons } from '../general/generalUtil';

interface Props {
  error: any;
}

export const Error = (props: Props) => (
  <div className="dialog__content__body">
    <div className="information-box">
      <i className={`material-icons big ${icons[props.error.type].color}`}>{ icons[props.error.type].name }</i>
      <span className="form-group">
        <FormattedMessage id={props.error.title} />
      </span>
      <label>
        <small>
          <FormattedHTMLMessage id={`${props.error.title}_desc`} />
        </small>
      </label>
    </div>
  </div>
);
