import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { redirectToUrl, url } from '../general/generalUtil';
import { Prescription } from '../general/models';

interface Props {
  prescription: Prescription | undefined;
  reference: string | undefined;
  productId: string | undefined;
  redirect: string | undefined;
  checkReference: (reference: string) => any;
}

export class Reference extends React.Component<Props> {
  handleKeyDown = (event: any) => {
    // only allow a-z, A-Z, digits 0-9 and comma ...
    if (!event.key.match(/[a-zA-Z0-9]/)) {
      event.preventDefault();
    }
  };

  handleKeyUp = (event: any) => {
    let reference = '';
    for (let i = 0; i < 8; i += 1) {
      reference = `${reference}${event.currentTarget[`field${i}`].value}`;
    }

    if (reference.length === 8) {
      this.props.checkReference(reference.toUpperCase());
    }

    if (event.key.length === 1) {
      this.focusOnNext(event.target);
      return;
    }

    if (event.keyCode === 37 || event.keyCode === 8) {
      // Right & Enter
      this.focusOnPrev(event.target);
    } else if (event.keyCode === 39 || event.keyCode === 13) {
      // Left & Backspace
      this.focusOnNext(event.target);
    }
  };

  focusOnNext = (target: any) => {
    if (target.name !== 'field3') {
      if (target.nextSibling) {
        target.nextSibling.focus();
      }
    } else {
      target.nextSibling.nextSibling.focus();
    }
  };

  focusOnPrev = (target: any) => {
    if (target.name !== 'field4') {
      if (target.previousSibling) {
        target.previousSibling.focus();
      }
    } else {
      target.previousSibling.previousSibling.focus();
    }
  };

  handleFocus = (event: any) => {
    event.target.select();
  };

  handleProceed = () => {
    if (this.props.prescription && this.props.productId) {
      let redirectUrl = `${url.pages}/checkout/?productId=${this.props.productId}&data.prescriptionId=${this.props.prescription.id}`;
      if (this.props.redirect) {
        redirectUrl += `&redirect=${this.props.redirect}`;
      }
      redirectToUrl(redirectUrl);
    }
  };

  render() {
    let inputClass = 'hidden';
    let icon = '';
    let buttonDisabled = true;

    const { prescription, reference, productId } = this.props;

    if (reference != null) {
      if (prescription != null) {
        if (prescription.status === 'NOT_PAID') {
          inputClass = 'check';
          icon = 'check-circle';

          if (prescription.id && productId) {
            buttonDisabled = false;
          }
        } else {
          inputClass = 'paid';
          icon = 'heart';
        }
      } else {
        inputClass = 'warning';
        icon = 'question-circle';
      }
    }

    return (
      <>
        <form className="form-group inputs-group" onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} onFocus={this.handleFocus}>
          <input type="text" name="field0" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(0, 1)} />
          <input type="text" name="field1" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(1, 1)} />
          <input type="text" name="field2" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(2, 1)} />
          <input type="text" name="field3" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(3, 1)} />
          <span>-</span>
          <input type="text" name="field4" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(4, 1)} />
          <input type="text" name="field5" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(5, 1)} />
          <input type="text" name="field6" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(6, 1)} />
          <input type="text" name="field7" className={inputClass} maxLength={1} placeholder="__" defaultValue={reference && reference.substr(7, 1)} />
          <i className={`fa fa-${icon} fa-2x top-right ${inputClass}`} />
        </form>
        <div className={`form-group inputs-info ${inputClass}`}>
          <label>
            <small>{ reference && <FormattedHTMLMessage id={`prescription_${inputClass}`} /> }</small>
          </label>
        </div>
        <div className="form-group">
          <button className="button" disabled={buttonDisabled} onClick={this.handleProceed}>
            <FormattedMessage id="proceed_to_checkout" />
          </button>
        </div>
      </>
    );
  }
}
