/* eslint-disable camelcase */
export enum resultType {
  warning = 'warning',
  error = 'error',
  success = 'success',
  waiting = 'waiting',
  timeout = 'timeout',
}

export interface ErrorData {
  title: string;
  type: resultType;
}

export interface ResultData {
  title: string;
  type: resultType;
}
export interface Prescription {
  id: string;
  reference: string;
  status: string;
  package_id: string;
}
