import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { url } from '../general/generalUtil';

export const ReferenceInfo = () => (
  <div>
    <div className="prescription-info">
      <div className="reference-info">
        <div className="form-group">
          <h5>
            <FormattedMessage id="reference_title" />
          </h5>
        </div>
        <div className="form-group">
          <label>
            <small>
              <FormattedHTMLMessage id="reference_desc" />
            </small>
          </label>
        </div>
        <div className="form-group">
          <label>
            <small>
              <FormattedHTMLMessage id="prescription_desc" />
            </small>
          </label>
        </div>
      </div>
      <div>
        <img className="img-prescription" title="prescription" alt="prescription" src={`${url.images}/Prescription.svg`} />
      </div>
    </div>
  </div>
);
