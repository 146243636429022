import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import 'url-polyfill';
import * as Sentry from '@sentry/react';
import App from './App';
import { url } from './general/generalUtil';
import withClearCache from './withClearCache';

if (window.location.hostname !== 'localhost') {
  Sentry.init({ dsn: url.sentry });
}

const ClearCacheComponent = withClearCache(App);

ReactDOM.render(<ClearCacheComponent />, document.getElementById('root') as HTMLElement);
