import * as qs from 'qs';
import { resultType } from './models';

export const languages = {
  en: 'English',
  nl: 'Nederlands',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español',
  it: 'Italiano',
  da: 'Danish',
};

export const url = {
  apx: process.env.REACT_APP_TARGET_HOST,
  pages: process.env.REACT_APP_URL,
  images: `${process.env.REACT_APP_URL}/assets/images`,
  sentry: process.env.REACT_APP_SENTRY_ERROR_TRACKING_DSN,
};

export const errors = {
  orderNotFound: { title: 'order_not_found', type: resultType.warning },
  productNotFound: { title: 'product_not_found', type: resultType.warning },
  wrongParameters: { title: 'wrong_parameters', type: resultType.error },
  unexpectedError: { title: 'unexpected_error', type: resultType.error },
};

export const results = {
  purchaseSuccess: { title: 'purchase_success', type: resultType.success },
  purchaseFailed: { title: 'purchase_failed', type: resultType.error },
  purchaseProgress: { title: 'purchase_progress', type: resultType.waiting },
  purchaseProgressTimeout: { title: 'purchase_progress_timeout', type: resultType.timeout },
  purchaseReceived: { title: 'purchase_received', type: resultType.waiting },
  purchaseReceivedTimeout: { title: 'purchase_received_timeout', type: resultType.timeout },
};

export const icons = {
  warning: { name: 'help', color: 'orange' },
  error: { name: 'highlight_off', color: 'red' },
  success: { name: 'check_circle', color: 'green' },
  waiting: { name: 'history', color: '' },
  timeout: { name: 'error_outline', color: '' },
};

export function getMessages(language: string) {
  try {
    return require(`../locales/${language}.json`);
  } catch (err) {
    return require('../locales/en.json');
  }
}

export function redirectToUrl(uri: string) {
  if (!uri || !uri.startsWith('http')) {
    return;
  }

  const redirectUrl = new URL(uri);
  const query = parseUrl(redirectUrl.search);

  let href = `${redirectUrl.origin}${redirectUrl.pathname}`;

  if (Object.keys(query).length !== 0) {
    href += `?${qs.stringify(query, { encodeValuesOnly: true })}`;
  }

  const anchor = uri.split('#')[1] ? `#${uri.split('#')[1]}` : '';
  window.location.href = `${href}${anchor}`;
}

export function parseUrl(search: string) {
  return qs.parse(search, { ignoreQueryPrefix: true });
}

export function isReference(ref: string) {
  const reference = new RegExp('^[A-Z0-9]{8}$');

  return reference.test(ref);
}

export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
