import * as React from 'react';
import { addLocaleData } from 'react-intl';
import * as localeDa from 'react-intl/locale-data/da';
import * as localeDe from 'react-intl/locale-data/de';
import * as localeEn from 'react-intl/locale-data/en';
import * as localeEs from 'react-intl/locale-data/es';
import * as localeFr from 'react-intl/locale-data/fr';
import * as localeIt from 'react-intl/locale-data/it';
import * as localeNl from 'react-intl/locale-data/nl';
import { Route, Router } from 'react-router-dom';
import './App.css';
import CheckOut from './Checkout';
import history from './History';

addLocaleData([...localeNl, ...localeEn, ...localeFr, ...localeDe, ...localeEs, ...localeIt, ...localeDa]);

class App extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  public render() {
    return (
      <Router history={history}>
        <Route path="/" component={CheckOut} />
      </Router>
    );
  }
}

export default App;
