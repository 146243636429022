import { url } from '../general/generalUtil';

export const ImageContainer = () => (
  <div
    className="dialog__visual"
    style={
      {
        backgroundImage: `url('${url.images}/pexels-photo-630839.jpeg')`,
      }
    }
  >
    <span className="dialog__visual__logo">
      <img alt="FibriCheck logo" title="FibriCheck" src={`${url.images}/logo.png`} />
      <span>FibriCheck</span>
    </span>
  </div>
);
